import * as errorConsts from './errors';
import * as eventsConsts from './events';

export const AUTH_TOKEN_NAME = 'authToken'
export const AuthToken = localStorage.getItem(AUTH_TOKEN_NAME)

export const BaseApiUrl = process.env.REACT_APP_API_URL || "http://localhost:80"


export const AppVersion = process.env.npm_package_version || require('../../../package.json').version || "0.0.6!"

export { errorConsts, eventsConsts };

