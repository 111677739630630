import { Button } from '@material-ui/core';
import { Formik, FormikActions } from 'formik';
import React from 'react';
import { VALIDATION_ERROR_CODE } from 'src/static/consts/errors';
import { ResLogin } from 'src/store/auth/types';
import { regStrs } from 'src/util';
import SimpleInput from '../input';
import { FormBase, FormBaseProps } from './formBase';


interface FormValues {
    email: string,
    password: string
}
interface FormLoginProps {
    onSubmitLogin: (data: FormValues, event?: React.FormEvent<HTMLFormElement>) => Promise<ResLogin>
}

const EMAIL = "email"
const PASSWORD = "password"
export default function FormLogin(props: FormLoginProps & FormBaseProps) {
    const { onSubmitLogin, onSubmitOk, onSubmitFail, ...otherProps } = props


    const validate = (v: FormValues) => {

        let errors: Partial<FormValues> = {}
        if (!v.email) {
            errors.email = 'Required';
        } else if (regStrs.isEmail(v.email)) {
            errors.email = 'Invalid email address';
        }
        if (!v.password) {
            errors.password = "Required"
        }
        return errors;
    }
    const handleSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
        try {
            const data = await onSubmitLogin(values)
            if (data.error)
                switch (data.error.code) {
                    case VALIDATION_ERROR_CODE: {
                        let errors: Partial<FormValues> = {}
                        data.error.fields!.forEach(f => {
                            if (f.path[f.path.length - 1] === "password") errors.password = f.message
                            else if (f.path[f.path.length - 1] === "email") errors.email = f.message
                        })
                        formikActions.setErrors(errors)
                        break;
                    }
                    default: {
                        alert("Error:formLogin " + data.error.message)
                        onSubmitFail && onSubmitFail(data.error)
                        break;
                    }
                }
            else
                onSubmitOk && onSubmitOk(data)

            formikActions.setSubmitting(false)
        } catch (error) {
            formikActions.setSubmitting(false)
            onSubmitFail && onSubmitFail(error)
        }
    }
    return (<Formik
        initialValues={{ email: '', password: '' } as FormValues}
        validate={validate}
        onSubmit={handleSubmit}
    >
        {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid
        }) => (
                <FormBase {...otherProps} onSubmit={handleSubmit} noValidate>
                    <SimpleInput
                        required
                        fullWidth
                        autoFocus
                        id={EMAIL}
                        label={"Email Address"}
                        helperText={errors.email}
                        error={Boolean(errors.email)}
                        name={EMAIL}
                        type={EMAIL}
                        autoComplete={EMAIL}
                        onChange={handleChange}
                        value={values.email}
                    />
                    <SimpleInput
                        required
                        fullWidth
                        name={PASSWORD}
                        label={"Password"}
                        helperText={errors.password}
                        error={Boolean(errors.password)}
                        type={"password"}
                        id={PASSWORD}
                        autoComplete="current-password"
                        onChange={handleChange}
                        value={values.password}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        style={{ marginTop: 20 }}
                        disabled={isSubmitting || !isValid}
                    >Login</Button>
                </FormBase>

            )}
    </Formik>
    )
}