import { action, computed, observable } from 'mobx';
import { AUTH_TOKEN_NAME, eventsConsts } from 'src/static/consts/';
import { setAuthorization } from 'src/util/api';
import User from "../user/user";
import * as authApi from './api';
import { ReqLogin, ReqSignup } from './types';



class Auth {
    @observable user?: User
    @observable authenticated: boolean = false

    @computed
    public get token() {
        return localStorage.getItem(AUTH_TOKEN_NAME)
    }
    public set token(t: string | null) {
        if (t === null) {
            localStorage.removeItem(AUTH_TOKEN_NAME)
            setAuthorization("")
        } else {
            localStorage.setItem(AUTH_TOKEN_NAME, t)
            setAuthorization(t)
        }
    }

    constructor() {
        document.addEventListener(eventsConsts.AUTH_ERROR_EVENT, this.handlerAuthError.bind(this))
    }

    @action async login(props: ReqLogin) {
        const { data } = await authApi.login(props)
        if (data.authToken) {
            this.token = data.authToken
            await this.setUser()
        }
        return data
    }


    @action async signup(props: ReqSignup) {
        const { data } = await authApi.signup(props)
        return data
    }

    @action async setUser() {
        if (this.token) {
            try {
                this.user = new User()
                this.user.getInfo()
                this.authenticated = true

            } catch (error) {
                this.user = undefined
                this.authenticated = false
            }
        }
    }
    @action logout(): void {
        this.token = null
        this.authenticated = false
        this.user = undefined
    }

    private handlerAuthError(event: any) {
        this.logout()
    }
}

export default Auth