import { Box, createStyles, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, Typography } from '@material-ui/core'
import { Clear as RemoveIcon } from '@material-ui/icons'
import * as React from 'react'
import { IProduct } from 'src/store/pay/types'

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(2)
    },
    title: {
        marginBottom: theme.spacing(2) + 4
    },
    table: {

    },

    inputQuantity: {
        width: 35,
        display: "inline-block",
        marginLeft: theme.spacing(2),
        verticalAlign: "baseline"
    }
}))

export interface ProductsListProps {
    list: IProduct[],
    title?: string
    addToOrder?: (id: string) => void
    changeQuantity?: (id: string, value: string) => void,
    remove?: (id: string) => void
}

export default function ProductsTable({ list, addToOrder, title, changeQuantity = () => { }, remove }: ProductsListProps) {
    const classes = useStyles()
    return (<Box className={classes.root}>
        <Typography
            variant="h6"
            className={classes.title}
        >
            {title ? title : "Products"}
        </Typography>
        <Paper>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Products</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        {remove !== undefined && (<TableCell align="right">Remove</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list!.map(({ id, name, price, quantity }) => {
                        return (<TableRow key={id}>
                            <TableCell component="th" scope="row">{name}</TableCell>
                            <TableCell align="right">{price}</TableCell>
                            <TableCell align="right">{changeQuantity ? (<TextField
                                inputProps={{ style: { textAlign: 'center' } }}
                                type="text"
                                required
                                value={quantity}
                                onChange={(e) => changeQuantity(id, e.target.value)}
                                className={classes.inputQuantity} />) :
                                quantity}
                            </TableCell>

                            {remove !== undefined && (
                                <TableCell align="right">
                                    <IconButton onClick={() => remove(id)}><RemoveIcon /></IconButton>
                                </TableCell>
                            )}
                        </TableRow>)
                    })}
                </TableBody>
            </Table >
        </Paper>
    </Box>)
}