import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import { IProduct } from 'src/store/pay/types'
import ProductItem from './productItem'

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(1)
    }
}))

export interface ProductsListProps {
    list: IProduct[],
    title?: string,
    subtitle?: string,
    addToOrder?: (id: string) => void
    changeQuantity?: (id: string, value: string) => void
}

export default function ProductsGrid({ list, addToOrder, title, changeQuantity, subtitle }: ProductsListProps) {
    const classes = useStyles()
    return (<React.Fragment>
        <Typography
            variant="h6"
            className={classes.title}
        >
            {list.length === 0 ? "There are no products available" : (title ? title : "Products")}
        </Typography>
        <Typography variant="subtitle1">
            {list.length !== 0 ? (subtitle || "") : ""}
        </Typography>
        <Grid container spacing={1}>
            {list!.map(({ id, name, price, quantity, iamgeUrl, description }) => {
                return (<Grid item xs={12} key={id}>
                    <ProductItem
                        id={id}
                        name={name}
                        price={price}
                        quantity={quantity}
                        addToOrder={addToOrder}
                        changeQuantity={changeQuantity}
                        iamgeUrl={iamgeUrl}
                    />
                </Grid>)
            })}
        </Grid>
    </React.Fragment>
    )
}