import axios, { AxiosResponse } from 'axios';
import { AuthToken, BaseApiUrl, errorConsts } from 'src/static/consts';
import { Events } from '.';


export const setAuthorization = (t: string) => {
    axios.defaults.headers.common['Authorization'] = t
}


const codes: { [key: number]: number } = {
    10000: 500,
    20000: 404,
    30000: 400,
    30001: 400,
    30002: 401,
    30003: 403
}
export interface ResApi {
    message?: string,
    error?: ResError
}
export interface ResError {
    code: number,
    message: object,
    fields?: ErrorValidationField[]
}
interface ErrorValidationField {
    message: string,
    path: string[],
    type: string
}
export const errorHandler = async (res: Promise<AxiosResponse<any>>) => {
    const { data } = await res as AxiosResponse<{ error?: ResError }>

    if (data.error) {
        const { code } = data.error
        switch (code) {
            case errorConsts.AUTH_ERROR_CODE: {
                document.dispatchEvent(Events.createAuthError())
                break
            }
            default:
                break;
        }
    }
    return res

}



axios.defaults.baseURL = BaseApiUrl + '/api/v1'
axios.defaults.validateStatus = (status): boolean => status < 500
axios.defaults.headers.common['Authorization'] = AuthToken || ""

export default axios