import * as React from 'react';
import { HomePage, LoginPage, SignupPage } from "src/pages";
import { PagesProps } from './types/props';

interface IRoute {
    path: string,
    title: (title?: string) => string,
    main: (props: PagesProps) => JSX.Element,
    exact?: boolean
}

export const HOME_PAGE: IRoute = {
    path: '/',
    title: (title?: string): string => title || "Welcome!",
    main: (props) => (<HomePage {...props} />),
    exact: true
}
export const SIGNUP_PAGE: IRoute = {
    path: '/signup',
    title: (title?: string): string => title || "SignUp",
    main: (props) => (<SignupPage {...props} />)
}
export const LOGIN_PAGE: IRoute = {
    path: '/login',
    title: (title?: string): string => title || "LogIn",
    main: (props) => (<LoginPage {...props} />)
}
export const listRoutes: IRoute[] = [HOME_PAGE, SIGNUP_PAGE, LOGIN_PAGE]
export default { HOME_PAGE, SIGNUP_PAGE, LOGIN_PAGE }