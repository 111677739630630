import { Button } from '@material-ui/core';
import { Formik, FormikActions } from 'formik';
import React from 'react';
import { VALIDATION_ERROR_CODE } from 'src/static/consts/errors';
import { ResSignup } from 'src/store/auth/types';
import { regStrs } from 'src/util';
import { SimpleInput } from '..';
import FormBase, { FormBaseProps } from './formBase';

interface FormValues {
    email: string,
    password: string,
    passwordConfirm: string,
    [key: string]: string
}

interface FormLoginProps {
    onSubmitSignup: (data: FormValues, event?: React.FormEvent<HTMLFormElement>) => Promise<ResSignup>
}
const EMAIL = "email"
const PASSWORD = "password"
const PASSWORD_CONFIRM = "passwordConfirm"
export default function FormSignup(props: FormLoginProps & FormBaseProps) {
    const { onSubmitSignup, onSubmitFail, onSubmitOk, ...otherProps } = props
    const validate = (v: FormValues) => {

        let errors: Partial<FormValues> = {}
        if (!v.email) {
            errors.email = 'Required';
        } else if (regStrs.isEmail(v.email)) {
            errors.email = 'Invalid email address';
        }
        if (!v.password) {
            errors.password = "Required"
        }
        if (!v.passwordConfirm) {
            errors.passwordConfirm = "Required"
        } else if (v.password !== v.passwordConfirm) {
            errors.passwordConfirm = "Passwords do not match"
        }
        return errors;
    }
    const handleSubmit = async (values: FormValues, formikActions: FormikActions<FormValues>) => {
        try {
            const data = await onSubmitSignup(values)
            if (data.error)
                switch (data.error.code) {
                    case VALIDATION_ERROR_CODE: {
                        let errors: Partial<FormValues> = {}
                        data.error.fields!.forEach(f => {
                            const place = f.path[f.path.length - 1]
                            errors[place] = f.message
                            // if (f.path[f.path.length - 1] === "password") errors.password = f.message
                            // else if (f.path[f.path.length - 1] === "passwordConfirm") errors.passwordConfirm = f.message
                            // else if (f.path[f.path.length - 1] === "email") errors.email = f.message
                        })
                        formikActions.setErrors(errors)
                        break;
                    }
                    default: {
                        alert("Error:formSignup " + data.error.message)
                        onSubmitFail && onSubmitFail(data.error)
                        break;
                    }
                }
            else
                onSubmitOk && onSubmitOk(data)

            formikActions.setSubmitting(false)
        } catch (error) {
            formikActions.setSubmitting(false)
            onSubmitFail && onSubmitFail(error)
        }
    }
    return (
        <Formik
            initialValues={{ email: '', password: '', passwordConfirm: '' } as FormValues}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                isValid,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                    <FormBase {...otherProps} onSubmit={handleSubmit} noValidate>
                        <SimpleInput
                            required
                            fullWidth
                            autoFocus
                            id={EMAIL}
                            label={"Email Address"}
                            helperText={errors.email}
                            error={Boolean(errors.email)}
                            name={EMAIL}
                            type={EMAIL}
                            autoComplete={EMAIL}
                            onChange={handleChange}
                            value={values.email}
                        />
                        <SimpleInput
                            required
                            fullWidth
                            name={PASSWORD}
                            label={"Password"}
                            helperText={errors.password}
                            error={Boolean(errors.password)}
                            type={"password"}
                            id={PASSWORD}
                            autoComplete="current-password"
                            onChange={handleChange}
                            value={values.password}
                        />
                        <SimpleInput
                            required
                            fullWidth
                            name={PASSWORD_CONFIRM}
                            type="password"
                            id={PASSWORD_CONFIRM}
                            autoComplete="current-password"
                            label="Confirm password"
                            helperText={errors.passwordConfirm}
                            error={Boolean(errors.passwordConfirm)}
                            onChange={handleChange}
                            value={values.passwordConfirm}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            style={{ marginTop: 20 }}
                            disabled={isSubmitting || !isValid}
                        >Signup</Button>
                    </FormBase>

                )}
        </Formik>

    )
}