import { Avatar, Container, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { VerifiedUser as IconUser } from '@material-ui/icons';
import { inject } from 'mobx-react';
import * as React from 'react';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { GlobalAppBar } from 'src/components';
import { FormSignup } from 'src/components/form';
import { StoresType } from 'src/store';
import { ResSignup } from 'src/store/auth/types';
import { GlobalProps } from 'src/types';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        alignItems: 'center'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
}));


export function SignupPage({ auth, title, history }: GlobalProps.LoginPageProps) {


    const classes = useStyles();



    const handleSubmitOk = (data: ResSignup) => {
        alert(data.message)
        history.push('/login')
    }
    const handleSubmitFail = (error?: any) => {
        console.log(error);
    }

    useEffect(() => {
        if (auth!.authenticated) history.push("/")
    })

    return (
        <React.Fragment>
            <Route render={(rProps) => (<GlobalAppBar {...rProps} title={title} />)} />
            <Container component="main" maxWidth="xs" className={classes.root}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <IconUser />
                    </Avatar>
                    <Typography component="h1" variant="h5">SignUp</Typography>
                    <FormSignup onSubmitSignup={auth!.signup.bind(auth)} onSubmitOk={handleSubmitOk} onSubmitFail={handleSubmitFail} />
                </Paper>
            </Container>
        </React.Fragment>
    )

}

export default inject((stores: StoresType) => ({
    auth: stores.AuthStore
}))(SignupPage)