import { observable } from 'mobx';
import AppStore from './app';
import Auth from './auth';
import Pay from './pay';
import User from './user';

export { AppStore, User as UserStore, Auth as AuthStore, Pay as PayStore };
export const stores = observable({
    AuthStore: new Auth(),
    PayStore: new Pay()
})
export type StoresType = typeof stores


export default stores