import { observable } from "mobx";
import Auth from "../auth/";
import Pay from "../pay";


class AppStore {
    @observable auth: Auth
    @observable pay: Pay
    constructor() {
        this.auth = new Auth()
        this.pay = new Pay()
    }
}

export default AppStore