import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CardsImg } from 'src/static/images';
import theme from 'src/static/theme';
import { checkBrowser } from 'src/util';


const CARD_MARGIN_TOP = 100
const CARD_MARGIN_LEFT = 130
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative',
        marginBottom: 10,
        marginRight: 10,
    },
    card: {
        background: 'linear-gradient(to bottom, #FFFFFF, #DADCDF)',
        borderRadius: 8,
        boxShadow: theme.shadows[2],
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "absolute",
        top: -CARD_MARGIN_TOP + 10,
        left: 0,
        zIndex: 10,
    },
    cardBack: {
        background: 'linear-gradient(to bottom, #FFFFFF, #DADCDF)',
        borderRadius: 8,
        boxShadow: theme.shadows[2],
        padding: theme.spacing(3),
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginTop: CARD_MARGIN_TOP,
        marginLeft: CARD_MARGIN_LEFT,
    },
    cardNumberList: {
        display: 'flex',
        justifyContent: "space-between",
        marginRight: - theme.spacing(1),
        marginLeft: - theme.spacing(1),
    },
    cardNumberInput: {
        width: "100%",
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        height: 30,
        textAlign: "center"
    },
    cardDateBox: {
        display: 'flex',
        justifyContent: "center",
        // marginTop: theme.spacing(3),
        alignItems: "flex-end"
    },
    cardDateInput: {
        width: 50,
        height: 30,
        textAlign: "center",
        marginRight: 10

    },
    cardNameInput: {
        width: "100%",
        height: 30,
        // marginTop: theme.spacing(3)
    },
    cardCvvInput: {
        width: 80,
        height: 30,
    },
    cardTypeImage: {
        maxWidth: 60,
        height: "auto"
    },
    cardsImage: {
        maxWidth: "100%",
        height: 'auto'
    }
}))

interface PayCardProps {
    children?: React.ReactNode,
    width?: number | '100%',
    handleChange?: typeof Formik.prototype.handleChange,
    values: CardValues,
    errors?: CardErrors,
    setFieldValue: typeof Formik.prototype.setFieldValue
}
export interface CardValues {
    number: string,
    name: string,
    date: string,
    cvv: number
}
export interface CardErrors {
    number: string,
    name: string,
    date: string,
    cvv: string
}

const CardNumberWindows = [1, 2, 3, 4]

export default function PayCart(props: PayCardProps) {
    const { width = 400, setFieldValue, values, errors } = props
    const cardRef = useRef<HTMLDivElement>(null)
    const cardBackRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState<number>(0)
    const [number, setNumber] = useState<{ [id: number]: string }>({ 1: "", 2: "", 3: "", 4: "" })
    const [date, setDate] = useState<{ month: number | "", year: number | "" }>({ month: "", year: "" })
    const [name, setName] = useState('')
    const [cvv, setCvv] = useState<number | ''>('')
    const classes = useStyles()
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {

        if ((e.keyCode >= 96 && e.keyCode <= 111) || e.keyCode === 9) e.preventDefault()
    }
    function handleResize() {
        if (cardRef && cardRef.current) {
            const w = cardRef.current.offsetWidth
            const h = (Number(w!) / 16) * 9
            setHeight(Math.round(h))
        }
    }
    function handleChangeNumber(id: number) {
        if (CardNumberWindows.indexOf(id) < 0) return

        return async function (e: React.ChangeEvent<HTMLInputElement>) {
            e.preventDefault()
            try {
                const num = parseInt(e.target.value)
                if (num > 0) {
                    await setNumber({ ...number, [id]: num.toString() })
                    !changed && setChanged(true)

                    // if (num.toString().length === 4 && id < 4 && id > 0) {
                    //     const nexTab = document.getElementById(`card-input-number-${(id + 1)}`)
                    //     if (nexTab) nexTab.focus()
                    // }
                } else if (e.target.value === "") {
                    await setNumber({ ...number, [id]: "" })
                    // if (id < 5 && id > 1) {
                    //     const nexTab = document.getElementById(`card-input-number-${(id - 1)}`)
                    //     if (nexTab) nexTab.focus()
                    // }
                }
                else throw new Error()

            } catch (error) {
                return
            }
        }
    }
    function handleChangeDate(type: "month" | "year", key: number) {
        return async function (e: React.ChangeEvent<HTMLInputElement>) {
            e.preventDefault()
            console.log("PIP");

            try {
                const num = parseInt(e.target.value)

                if (num > 0) {
                    if (type === "month" && num > 12) return
                    await setDate({ ...date, [type]: num })
                    !changed && setChanged(true)

                } else if (e.target.value === "") {
                    await setDate({ ...date, [type]: "" })
                    !changed && setChanged(true)
                }
            } catch (error) {
                return
            }
        }
    }
    function handleChangeCvv(e: React.ChangeEvent<HTMLInputElement>) {
        try {
            const num = parseInt(e.target.value)
            if (num > 0 || e.target.value === "") {
                setCvv(num || "")
                !changed && setChanged(true)
            }
        } catch (error) {
            return
        }
    }
    function VCardNumberItem(id: number) {
        const error = errors && errors.number && errors.number.length !== 0
        return (
            <input
                onKeyDown={handleKeyDown}
                key={id}
                type="text"
                name={"card-input-number-" + id}
                id={"card-input-number-" + id}
                className={classes.cardNumberInput}
                tabIndex={id}
                maxLength={4}
                value={number[id]}
                onChange={handleChangeNumber(id)}
                style={{ backgroundColor: error ? theme.palette.error.light : '#fff' }}
                required
            />
        )
    }
    function VCardDateItem(type: "month" | "year", key: number) {
        const error = errors && errors.date && errors.date.length !== 0
        return (
            <input
                onKeyDown={handleKeyDown}
                key={key}
                type="text"
                name={"card-input-date-" + key}
                id={"card-input-date-" + key}
                className={classes.cardDateInput}
                tabIndex={key + 4}
                maxLength={2}
                value={date[type]}
                onChange={handleChangeDate(type, key)}
                style={{ backgroundColor: error ? theme.palette.error.light : '#fff' }}
                required
            />
        )
    }
    function VError(errors: Partial<CardErrors>) {
        // console.log("Card:field_errors: ", errors)
    }
    useEffect(() => {
        changed && setFieldValue("card.number", "" + number[1] + number[2] + number[3] + number[4])
    }, [number])

    useEffect(() => {
        changed && setFieldValue("card.date", `${date["month"]}:${date["year"].toString().length === 1 ? '0' + date["year"].toString() : date["year"].toString()}`)
    }, [date])

    useEffect(() => {
        changed && setFieldValue("card.cvv", cvv.toString())
    }, [cvv])

    useEffect(() => {
        changed && setFieldValue("card.name", name)
    }, [name])
    return (
        <div className={classes.root}>
            <div
                className={classes.card}
                ref={cardRef}
                style={{ width: '100%', maxWidth: width, height: height }} >

                <div>
                    <Typography variant="caption">
                        CARD NUMBER
                    </Typography>
                    <div className={classes.cardNumberList}>
                        {CardNumberWindows.map(VCardNumberItem)}
                    </div>
                </div>
                <div className={classes.cardDateBox}>
                    <Typography variant="caption" style={{ width: 40, lineHeight: 1.2 }} component="p">
                        VALID THRU
                    </Typography>
                    <div>
                        <Typography variant="caption" style={{ width: 50 }} component="p" align="center">
                            MONTH
                    </Typography>
                        {VCardDateItem("month", 1)}
                    </div>
                    <div style={{ marginRight: 30 }}>
                        <Typography variant="caption" style={{ width: 50 }} component="p" align="center">
                            YEAR
                    </Typography>
                        {VCardDateItem("year", 2)}
                    </div>
                </div>
                <div>
                    <input
                        onKeyDown={handleKeyDown}
                        placeholder="Cardholder name"
                        type="text"
                        name={"card-input-name"}
                        id={"card-input-name"}
                        className={classes.cardNameInput}
                        tabIndex={7}
                        value={name}
                        required
                        onChange={(e) => {
                            setName(e.target.value)
                            !changed && setChanged(true)
                        }}
                        style={{ backgroundColor: errors && errors.name ? theme.palette.error.light : '#fff' }}
                    />
                </div>
            </div>

            <div
                className={`${classes.cardBack}`}
                ref={cardBackRef}
                style={{ width: '100%', maxWidth: width, height: height }} >
                <div style={{ width: "120%", height: 30, backgroundColor: '#5b5f68', marginRight: -theme.spacing(3) }}></div>
                <Typography variant="caption" style={{ width: 100 }} component="p" align="right">
                    CVV/CVC
                </Typography>
                <input
                    onKeyDown={handleKeyDown}
                    type="text"
                    name={"card-input-cvv"}
                    id={"card-input-cvv"}
                    className={classes.cardCvvInput}
                    tabIndex={8}
                    value={cvv.toString()}
                    maxLength={3}
                    required
                    onChange={handleChangeCvv}
                    style={{ backgroundColor: errors && errors.cvv ? theme.palette.error.light : '#fff' }}
                />
                {/* <img src={VisaImg} alt="VisaImg" className={classes.cardTypeImage} /> */}
                {/* <img src={MasterCardImg} alt="MasterCardImg" className={classes.cardTypeImage} /> */}
                <img src={CardsImg} alt="Cards" className={classes.cardsImage} hidden={checkBrowser.isSafari} />
            </div>
            {errors && VError(errors)}
        </div>
    )
}