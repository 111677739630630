
import { AxiosResponse } from 'axios';
import api, { errorHandler } from 'src/util/api';
import { ReqOrderPay, ResGetListProducts, ResGetProduct, ResOrderPay } from './types';


export const createOrderAndPay = async (data: ReqOrderPay): Promise<AxiosResponse<ResOrderPay>> => {
    return await errorHandler(api.post('/order/createAndPay', { ...data }))
}

export const getRandomProduct = async (data?: any): Promise<AxiosResponse<ResGetProduct>> => {
    return await errorHandler(api.get('/product/random', { ...data }))
}

export const getListOfProducts = async (data?: any): Promise<AxiosResponse<ResGetListProducts>> => {
    return await errorHandler(api.get('/product/'))
}