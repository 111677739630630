import { createMuiTheme } from "@material-ui/core";
import * as color from "@material-ui/core/colors";
import { checkBrowser } from "src/util";

export default createMuiTheme({
    palette: {
        primary: {
            dark: "#0A4467",
            main: "#1e779e",
            light: '#51A8D6'
        },
        secondary: {
            dark: "#a83e19",
            main: checkBrowser.isEdge ? color.red["A400"] : "#f15a24",
            light: "#F39B7C",
        },
        background: {
            default: 'linear-gradient(35deg, #6863bf 0%, #067d93 50%, #e68a68 100%)'
        },
        error: {
            main: color.red[900],
            light: checkBrowser.isFirefox ? color.lightGreen[400] : color.red[400]
        }

    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 14
            }
        },
    }
});