import { Box, Typography } from '@material-ui/core/'
import * as React from 'react'
import { IProduct } from 'src/store/pay/types'
import { ProductTable } from '../product'

export interface OrderPanelProps {
    orderList: IProduct[],
    sale?: number
    changeQuantity?: (id: string, value: string) => void,
    remove?: (id: string) => void
}

export default function OrderPanel({ orderList, changeQuantity, sale, remove }: OrderPanelProps) {
    let sum = 0
    let quantity = 0
    orderList.forEach(p => {
        if (p.quantity) {
            sum += p.price * p.quantity
            quantity += p.quantity

        }
    })

    if ((quantity >= 3 && quantity <= 11) && sale) sum *= (1 - sale)
    return (<Box>
        <ProductTable list={orderList} title="Chart" changeQuantity={changeQuantity} remove={remove} />
        <Typography variant="h6" style={{ marginTop: 10, marginBottom: 20 }}>
            Total: {sum}
        </Typography>
    </Box>)
}