import { Box, Container, Paper, Typography } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { GlobalAppBar, PrivateComponent, ShopPanel } from 'src/components';
import theme from 'src/static/theme';
import { StoresType } from 'src/store';
import { GlobalProps } from 'src/types';



@inject((stores: StoresType) => ({
    auth: stores.AuthStore,
    pay: stores.PayStore
}))
@observer
class HomePage extends React.Component<GlobalProps.HomePageProps> {
    render() {
        const { auth, title, pay } = this.props
        let appBarTitle = title
        if (auth && auth.authenticated && auth.user) {
            appBarTitle = title + " | " + auth.user.email
        }
        return (
            <React.Fragment>
                <Route render={(props) => (<GlobalAppBar {...props} title={appBarTitle} />)} />
                <Container style={{ flexGrow: 1 }} maxWidth="lg">
                    <PrivateComponent
                        publicComponent={publicHomeView()}
                        auth={auth}>
                        <ShopPanel pay={pay} />
                    </PrivateComponent>
                </Container>
            </React.Fragment>
        )

    }
}

function publicHomeView() {
    return (<Box display="flex" justifyContent="center" alignItems="center" height="100%" >
        <Paper style={{
            marginTop: theme.spacing(2),
            padding: theme.spacing(2)
        }}>
            <Typography variant='h6' align="center">
                To make purchases, you need to sign up. <br />
                If you alredy have an account, you need to log in.
            </Typography>
        </Paper>
    </Box >

    )
}
export default HomePage