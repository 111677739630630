import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import React from 'react';

interface InputProps {

}

export default function Input(props: InputProps & TextFieldProps) {
    const { margin, ...otherProps } = props
    return (
        <TextField
            {...otherProps}
            variant={"outlined"}
            margin={"normal"}
        />
    )
}
