import { action, observable } from "mobx";
import * as UserApi from './api';
interface IUser {
    id?: string
    email?: string
}
class User {
    @observable id: string
    @observable email: string
    constructor(props: IUser = {}) {
        const { id, email } = props
        this.id = id || ''
        this.email = email || ''
    }

    @action
    public async getInfo() {
        const { data } = await UserApi.getUserInfo()

        if (data.email && data.id) {
            this.id = data.id
            this.email = data.email
        }
    }
}

export default User