import { action, computed, observable } from 'mobx';
import * as payApi from './api';
import { IOrderProduct, IProduct, ReqOrderPay } from "./types";

class Pay {

    @observable products: Map<string, IProduct>
    @observable order: Map<string, IOrderProduct>
    @observable sale?: number

    @computed
    get productsArray() {
        return Array.from(this.products.values())
    }

    @computed
    get orderArray() {
        return Array.from(this.order.values())
    }

    constructor() {
        this.products = new Map()
        this.order = new Map()
    }

    @action
    getRandomProduct = async () => {
        const { data } = await payApi.getRandomProduct()
        if (!data.error && data.product) {
            this.products.clear()
            this.products.set(data.product.id, { ...data.product, id: data.product.id.toString() })
        }
    }

    @action
    getListOfProducts = async () => {
        const { data } = await payApi.getListOfProducts()
        if (!data.error && data.products) {
            this.products.clear()
            data.products.forEach((product) => {
                this.products.set(product.id, { ...product, id: product.id.toString() })
            })
            this.sale = data.sale
        }
    }

    @action
    addToOrder = (id: string) => {
        if (this.products.has(id)) {
            this.order.set(id, { id, quantity: 1 })
        }
    }
    @action
    changeQuantityProductInOrder = (id: string, value: string) => {
        if (this.order.has(id)) {
            const p = this.order.get(id)!
            p.quantity = Number(value)
            this.order.set(id, p)
        }
    }

    @action
    createOrderAndPay = async (req: ReqOrderPay) => {
        const { data } = await payApi.createOrderAndPay(req)
        return data
    }

    @action
    removeFromOrder = (id: string) => {
        return this.order.delete(id)
    }
}
export default Pay