import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import theme from 'src/static/theme';


interface Props {
    open: boolean,
    onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason?: "backdropClick" | "escapeKeyDown") => void
}
export default function RulesDiaolg({ onClose, open }: Props) {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div>
                <DialogTitle id="alert-dialog-title">{"To use the service, you must carefully read and agree to the following terms and conditions:"}</DialogTitle>
                {onClose && (
                    <IconButton
                        aria-label="close"
                        onDoubleClick={onClose}
                        size="small"
                        style={{
                            position: 'absolute',
                            right: theme.spacing(1),
                            top: theme.spacing(1),
                            color: theme.palette.grey[500],
                        }}>
                        <CloseIcon />
                    </IconButton>
                )}

            </div>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" paragraph>
                    1. General<br /><br />
                    1.1. The Service (hereinafter referred to as the Service) is online software for semi-automatic website registration in catalogs that accept English-language websites (hereinafter referred to as Registration).<br />
                    1.2. User Account - a combination of a username and password for accessing the features of the Service.<br />
                    1.3. Registration through the Service is available for websites with English-language content, except for websites specified in paragraphs 5.1-5.2.<br />
                </DialogContentText>
            </DialogContent>

        </Dialog>
    )
}