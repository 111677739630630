import { Button, Link, Typography } from '@material-ui/core';
import { Formik, FormikActions } from 'formik';
import React from 'react';
import { VALIDATION_ERROR_CODE } from 'src/static/consts/errors';
import { ResApi } from 'src/util/api';
import { PayCard } from '../shopPanel';
import { CardErrors, CardValues } from '../shopPanel/card';
import RulseDialog from '../shopPanel/rulesDialog';
import { FormBase, FormBaseProps } from './formBase';


export interface FormPayValues {
    card: CardValues,
    isAgreeWithRules: boolean
}

export interface FromPayErrors {
    card: Partial<CardErrors>,
    isAgreeWithRules: string
}


interface FormPayProps {
    onSubmitAction: (data: FormPayValues, event?: React.FormEvent<HTMLFormElement>) => Promise<ResApi | undefined> | void
}

const EMAIL = "email"
const PASSWORD = "password"
const AGREE_RULES = "isAgreeWithRules"
export default function FormPay(props: FormPayProps & FormBaseProps) {
    const { onSubmitAction, onSubmitOk, onSubmitFail, ...otherProps } = props
    const [openDialog, setDialogState] = React.useState(false)


    const validate = (v: FormPayValues) => {
        let error: Partial<FromPayErrors> = {}

        if (!v.isAgreeWithRules)
            error.isAgreeWithRules = "You did not agree with the rules"

        if (!v.card.date || v.card.date === "")
            error.card = { ...error.card, date: "Required" }

        if (!v.card.name || v.card.name === "")
            error.card = { ...error.card, name: "Required" }
        else if (v.card.name.length > 255)
            error.card = { ...error.card, name: "The line length must not exceed 255 characters" }

        if (!v.card.cvv)
            error.card = { ...error.card, cvv: "Required" }

        if (!v.card.number || v.card.number === "") {
            error.card = { ...error.card, number: "Required" }
        } else if (v.card.number.length !== 16) {
            error.card = { ...error.card, number: "Must be 16 characters" }
        }

        return error;
    }
    const handleSubmit = async (values: FormPayValues, formikActions: FormikActions<FormPayValues>) => {
        const data = await onSubmitAction(values)
        if (data && data.error) {
            switch (data.error.code) {
                case VALIDATION_ERROR_CODE: {
                    let e: Partial<FromPayErrors> = {}
                    data.error.fields!.forEach(f => {
                        if (f.path[f.path.length - 1] === "date") e.card = { ...e.card, date: f.message }
                        if (f.path[f.path.length - 1] === "cvv") e.card = { ...e.card, cvv: f.message }
                        if (f.path[f.path.length - 1] === "name") e.card = { ...e.card, name: f.message }
                        if (f.path[f.path.length - 1] === "number") e.card = { ...e.card, number: f.message }
                    })
                    formikActions.setErrors(e)
                    formikActions.setSubmitting(false)
                    break;
                }
                default: {
                    formikActions.setSubmitting(false)
                    alert(data.error.message)
                    break;
                }

            }
        }
        else
            formikActions.setSubmitting(false)
    }
    return (<React.Fragment>
        <Formik
            initialValues={{ isAgreeWithRules: false } as FormPayValues}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue
            }) => (
                    <FormBase {...otherProps} onSubmit={handleSubmit} noValidate>
                        <PayCard values={values.card} errors={errors.card as CardErrors} setFieldValue={setFieldValue} />

                        <Typography variant="subtitle2" style={{}} color={Boolean(errors.isAgreeWithRules) ? 'error' : 'textPrimary'}>
                            <input
                                id={AGREE_RULES}
                                name={AGREE_RULES}
                                type="checkbox"
                                checked={values.isAgreeWithRules}
                                onChange={handleChange} />
                            I agree to the <Link
                                component="button"
                                type="button"
                                variant="body2"
                                onClick={() => setDialogState(true)}>terms and conditions</Link>
                        </Typography>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting || !isValid}
                            style={{
                                marginTop: 20
                            }}>Complete</Button >
                    </FormBase>
                )}
        </Formik>

        <RulseDialog open={openDialog} onClose={(e) => setDialogState(false)} />
    </React.Fragment>
    )
}