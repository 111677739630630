import React from 'react';
import { AuthStore } from 'src/store';

interface Props {
    children?: React.ReactNode,
    auth?: AuthStore,
    publicComponent?: JSX.Element
}
function PrivateComponent({ auth, children, publicComponent }: Props) {
    if (auth && auth.authenticated && auth.user && children) return React.cloneElement(children as React.ReactElement, { auth })
    else if (publicComponent) {
        return (publicComponent)
    }
    else return null

}

export default PrivateComponent