import { Button, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Home as HomeIcon } from '@material-ui/icons';
import { AccountCircle } from '@material-ui/icons/';
import { inject } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import routes, { LOGIN_PAGE } from 'src/routes';
import { StoresType } from 'src/store';
import { AppBarProps } from 'src/types/props';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // flexGrow: 1,
            // paddingBottom: theme.spacing(8)
        },
        iconButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);



export function GlobalAppBar(props: AppBarProps) {
    const { title = "", location, auth } = props
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);



    function handleMenu(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose(event: React.MouseEvent<HTMLLIElement, MouseEvent>, name?: string) {
        setAnchorEl(null);
        if (name) {
            switch (name) {
                case 'logout': {
                    auth && auth.logout()
                    props.history.push(LOGIN_PAGE.path)
                    break;
                }

                default:
                    break;
            }
        }
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.iconButton}
                        color="inherit"
                        aria-label="link-to-home"
                        component={Link}
                        to={routes.HOME_PAGE.path}
                    >
                        <HomeIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                    {auth && !auth.authenticated ? (<div>

                        <Button
                            color="inherit"
                            component={Link}
                            to={routes.LOGIN_PAGE.path}
                        >
                            Login
                        </Button>
                        <Button
                            color="inherit"
                            component={Link}
                            to={routes.SIGNUP_PAGE.path}
                        >
                            Signup
                        </Button>
                    </div>
                    ) : (
                            <div>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {/* <MenuItem onClick={(e) => handleClose(e, "profile")}>Профиль</MenuItem> */}
                                    <MenuItem onClick={(e) => handleClose(e, "logout")}>Выйти</MenuItem>
                                </Menu>
                            </div>
                        )}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default inject((stores: StoresType) => ({
    auth: stores.AuthStore
}))(GlobalAppBar)