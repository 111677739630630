import { AxiosResponse } from 'axios';
import api, { errorHandler } from 'src/util/api';
import { ReqLogin, ReqSignup, ResLogin, ResSignup } from './types';


export const login = async ({ email, password }: ReqLogin): Promise<AxiosResponse<ResLogin>> => {
    return await errorHandler(api.post('/user/login', {
        email,
        password
    }))
}

export const signup = async ({ email, password, passwordConfirm }: ReqSignup): Promise<AxiosResponse<ResSignup>> => {
    return await errorHandler(api.post('/user/signup', {
        email,
        password,
        passwordConfirm
    }))
}