import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import { BaseApiUrl } from 'src/static/consts'
import { PoniImg } from 'src/static/images'
import { IProduct } from 'src/store/pay/types'


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        // padding: theme.spacing(2)
    },

    img: {
        // width: "100%",
        // height: "auto",
        // maxWidth: 200,
        // padding: theme.spacing(2),
        // boxSizing: "content-box"

        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    payStr: {
        color: '#0091D1'
    },
    inputAmount: {
        width: 35,
        display: "inline-block",
        marginLeft: theme.spacing(2),
        // marginBottom: theme.spacing(2),
        verticalAlign: "baseline"
    }
}))
export interface ProductItemProps extends IProduct {
    // price: number,
    // name: string,
    // id: string,
    // quantity?: number,
    changeQuantity?: (id: string, value: string) => void,
    addToOrder?: (id: string) => void
}

export default function ProductCard({ id, name, price, changeQuantity, quantity, addToOrder, description, iamgeUrl }: ProductItemProps) {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardHeader
                titleTypographyProps={{ style: { fontSize: 18 } }}
                title={name}
            />
            {/* <img src={PoniImg} alt="pay-poni" className={classes.poniImg} /> */}
            <CardMedia
                className={classes.img}
                image={iamgeUrl ? BaseApiUrl + iamgeUrl : PoniImg}
                title={name}
            />
            <CardContent>
                <Typography className={classes.payStr}>
                    Price {price.toString()} USD.
                        </Typography>
                {quantity !== undefined && changeQuantity &&
                    (<div>
                        <Typography className={classes.payStr} style={{ display: "inline-block" }}>
                            Quantity
                        </Typography>
                        <TextField
                            inputProps={{ style: { textAlign: 'center' } }}
                            type="text"
                            required
                            value={quantity}
                            onChange={(e) => changeQuantity(id, e.target.value)}
                            className={classes.inputAmount} />
                    </div>)
                }
                <Typography variant="body1">
                    {description}
                </Typography>

            </CardContent>

            <CardActions>
                {quantity === undefined && addToOrder &&
                    (<Button color="secondary" variant="contained" onClick={() => addToOrder(id)} fullWidth>
                        Add to chart
                </Button>)
                }
            </CardActions>

        </Card>
    )
}