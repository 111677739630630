import { Button, Card, CardContent, CardMedia, createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core'
import * as React from 'react'
import { BaseApiUrl } from 'src/static/consts'
import { PoniImg } from 'src/static/images'
import { IProduct } from 'src/store/pay/types'


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',

    },
    title: {
        height: 28,
        overflow: "hidden"
    },
    payStr: {
        color: '#0091D1'
    },
    inputAmount: {
        width: 35,
        display: "inline-block",
        marginLeft: theme.spacing(2),
        verticalAlign: "baseline"
    },
    cover: {
        width: 200,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}))
export interface ProductItemProps extends IProduct {
    // price: number,
    // name: string,
    // id: string,
    // quantity?: number,
    changeQuantity?: (id: string, value: string) => void,
    addToOrder?: (id: string) => void
}

export default function ProductItem({ id, name, price, changeQuantity, quantity, addToOrder, description, iamgeUrl }: ProductItemProps) {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardMedia
                className={classes.cover}
                image={iamgeUrl ? BaseApiUrl + iamgeUrl : PoniImg}
                title={name}
            />
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography variant="h5" className={classes.title} component="h5">
                        {name}
                    </Typography>
                    <Typography className={classes.payStr}>
                        Price {price.toString()} USD.
                        </Typography>
                    {quantity !== undefined && changeQuantity && (
                        <div>
                            <Typography className={classes.payStr} style={{ display: "inline-block" }}>
                                Quantity
                            </Typography>
                            <TextField
                                inputProps={{ style: { textAlign: 'center' } }}
                                type="text"
                                required
                                value={quantity}
                                onChange={(e) => changeQuantity(id, e.target.value)}
                                className={classes.inputAmount} />
                        </div>
                    )}
                    <Typography variant="body1">
                        {description}
                    </Typography>

                </CardContent>
                <div className={classes.controls}>
                    {quantity === undefined && addToOrder && (
                        <Button color="secondary" variant="contained" onClick={() => addToOrder(id)}>
                            Add to chart
                        </Button>
                    )}
                </div>
            </div>


            {/* <CardActions>
                {quantity === undefined && addToOrder &&
                    (<Button color="secondary" variant="contained" onClick={() => addToOrder(id)} fullWidth>
                        Добавить в заказ
                </Button>)
                }
            </CardActions> */}

        </Card>
    )
}