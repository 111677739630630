import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
}));

export interface FormBaseProps {
    children?: React.ReactNode
    onSubmitOk?: (data?: any) => void,
    onSubmitFail?: (data?: any) => void,
}

export function FormBase(props: FormBaseProps & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>) {
    const { children } = props
    const classes = useStyles();

    return (
        <form className={classes.form}  {...props}>
            {children}
        </form>
    )
}
export default FormBase