import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouterProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import './index.css';
import { HomePage } from './pages';
import { listRoutes } from './routes';
import { AppVersion } from './static/consts';
import theme from './static/theme';
import { AuthStore, StoresType } from './store';

interface Props {
    auth?: AuthStore
}

@inject((stores: StoresType) => ({
    auth: stores.AuthStore
}))
@observer
class App extends React.Component<Props & RouterProps> {
    componentWillMount() {
        const { auth } = this.props
        auth && auth.setUser()
    }
    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <main style={{ overflowY: "auto", height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: 'flex-start' }}>
                        <Switch>
                            {listRoutes.map((r, i) => {
                                return (<Route
                                    key={i}
                                    path={r.path}
                                    exact={r.exact}
                                    render={props => r.main({
                                        ...props,
                                        title: r.title()
                                    })}
                                />)
                            })}
                            <Route render={(props) => (<HomePage {...props} title="Страница не найдена" />)} />
                        </Switch>
                    </main>
                    <div style={{ position: "absolute", right: 30, bottom: 30 }}>
                        <span>{AppVersion}</span>
                    </div>
                </ThemeProvider>
            </React.Fragment>
        )
    }
}

export default App