import { Grid, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import { VALIDATION_ERROR_CODE } from 'src/static/consts/errors';
import { AuthStore, PayStore } from 'src/store';
import { ResApi } from 'src/util/api';
import { FormPay } from '../form';
import { FormPayValues } from '../form/formPay';
import { ProductGrid } from '../product';
import OrderPanel from './orderPanel';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(2)
    },
    subTitile: {
        fontWeight: 300,
        fontSize: 14
    },
    warning: {
        color: 'FireBrick',
        fontWeight: 500
    },
    poniImg: {
        width: "100%",
        height: "auto",
        maxWidth: 200,
        padding: theme.spacing(2),
        boxSizing: "content-box"
    },
    payStr: {
        color: '#0091D1'
    },
    inputAmount: {
        width: 35,
        display: "inline-block",
        marginLeft: theme.spacing(2),
        verticalAlign: "baseline"
    }
}))

interface Props {
    children?: React.ReactNode,
    auth?: AuthStore,
    pay?: PayStore
}
function ShopPanel({ pay, auth }: Props) {
    const classes = useStyles()

    React.useEffect(() => {

        if (auth!.authenticated) {
            console.log("getListOfProducts");
            pay!.getListOfProducts()
        } else {
            console.log("clear by else ");
            pay!.products.clear()
            pay!.order.clear()
        }
        return () => {
            console.log("clear by return ");
            pay!.products.clear()
            pay!.order.clear()
        }
    }, [auth!.authenticated])


    async function handleSubmitPay(date: FormPayValues, e?: React.FormEvent<HTMLFormElement>) {
        e && e.preventDefault()
        try {
            const res = await pay!.createOrderAndPay({
                card: {
                    ...date.card
                },
                products: pay!.orderArray
            })

            if (res.error) {
                if (res.error.code === VALIDATION_ERROR_CODE) {
                    if (res.error.fields!.find(f => f.path[2] === "card")) return res as ResApi
                    const errors: string[] = res.error.fields!.map(f => (f.message))
                    errors.push("Ошибка заполнения формы заказа:")
                    errors.reverse()
                    alert(errors.join('\n'))
                } else {
                    alert(res.error.message)
                    return
                }
            } else {
                alert(res.message)
                return
            }
        } catch (error) {
            console.log(error)
            return
        }
        return

    }

    function getOrderAsProducts() {
        return pay!.orderArray.map(i => {
            const product = pay!.products.get(i.id)!
            return {
                ...product,
                quantity: i.quantity
            }
        })
    }
    const saleTitle = pay!.sale !== undefined ? `When ordering 3 or more units of the product, inclusive, a ${pay!.sale * 100}% discount is provided.` : ''
    return (
        <React.Fragment>
            <Grid container spacing={3} className={classes.root} >
                <Grid item xs={6}>
                    <ProductGrid
                        list={pay!.productsArray}
                        addToOrder={pay!.addToOrder}
                        subtitle={saleTitle}
                    />
                </Grid>
                <Grid item xs={6}>
                    {pay!.order.size !== 0 && (
                        <OrderPanel
                            orderList={getOrderAsProducts()}
                            changeQuantity={pay!.changeQuantityProductInOrder}
                            sale={pay!.sale}
                            remove={pay!.removeFromOrder}
                        />
                    )}
                    <Typography variant="h6">
                        Credit card payment
                    </Typography>
                    <Typography variant="subtitle2" className={classes.subTitile}>
                        Enter the data exactly as it appears on your credit card.
                    </Typography>
                    <Typography variant="subtitle2" className={classes.warning}>          
                        PayForm is a website for learning purposes, and it doesn't provide the payment process itself. However, all data is stored in the database, so please DO NOT use real credit card information for your tests!
                    </Typography>
                    <FormPay onSubmitAction={handleSubmitPay} />

                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default observer(ShopPanel)