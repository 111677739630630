import { Avatar, Container, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { inject } from 'mobx-react';
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { GlobalAppBar } from 'src/components';
import { FormLogin } from 'src/components/form';
import { StoresType } from 'src/store';
import { ResLogin } from 'src/store/auth/types';
import { GlobalProps } from 'src/types';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        alignItems: 'center'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    }
}));
export function LoginPage(props: GlobalProps.SignupPageProps) {

    const classes = useStyles();
    const { auth } = props

    useEffect(() => {
        if (auth!.authenticated) props.history.push('/')
    }, [auth!.authenticated])


    const handleSubmitOk = (data: ResLogin) => {
        props.history.push('/')
    }
    const handleSubmitFail = (error?: any) => {
        console.log(error);
    }

    return (
        <React.Fragment>
            <Route render={(rProps) => (<GlobalAppBar {...rProps} title={props.title} />)} />
            <Container component="main" maxWidth="xs" className={classes.root}>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">Log in</Typography>
                    <FormLogin onSubmitLogin={auth!.login.bind(auth)} onSubmitOk={handleSubmitOk} onSubmitFail={handleSubmitFail} />
                </Paper>
            </Container>
        </React.Fragment>
    )
}
export default inject((stores: StoresType) => ({
    auth: stores.AuthStore
}))(LoginPage)